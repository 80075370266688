import {
  addData,
  getCountryCityCode,
  requestSignupOtp,
  setData,
  userLogin,
  userRegister,
  verifySignupOtp,
} from '@/actions/auth';
import APIServices from '@/apiUtils/APIServices';
import CustomCheckBox from '@/components/CustomCheckBox';
import countries, { country_codes } from '@/helpers/countries';
import { CountryCodeSelector } from '@/helpers/CountryCodeSelector';
import { authentication } from '@/helpers/Firebase/firebase-config';
import { blockInvalidChar } from '@/helpers/formDataHelper';
import { countdownHelper } from '@/helpers/timeHelper';
import useWidth from '@/helpers/widthUtil';
import { InfoCircleFilled } from '@ant-design/icons';
import {
  Alert,
  Button,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
  Tooltip,
  message,
  notification,
} from 'antd';
import axios from 'axios';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}
const Login = ({
  is_instructor,
  isLoginPage = false,
  isSignUpPage = false,
  isModal = false,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;

  const { isMobile } = useWidth();
  const [login, setLogin] = useState(true);
  const {
    fromLogoutPage,
    userData,
    userDataRegister,
    auth: { user, address, signUpStatus, getSignupOtpStatus, verifySignupOtpStatus },
  } = useSelector((state: any) => ({
    fromLogoutPage: state.auth.fromLogOut,
    userData: state.auth.creds,
    userDataRegister: state.auth.register,
    auth: state.auth,
  }));

  const router = useRouter();
  const [expandOtpForm, setExpandOtpForm] = useState(false);
  const [OTP, setOTP] = useState('');
  const [verifyError, setverifyError] = useState(null);
  const [password2, setPassword2] = useState('');
  const [disableResend, setDisableResend] = useState(false);
  const [resendClick, setResendClick] = useState(false);
  const [phoneFormVisible, setphoneFormVisible] = useState(false);
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    country_code: '',
    phone_number: '',
    password: '',
    referer: '',
  });
  const [otpLoader, setOTPLoader] = useState(false);
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [formData, setFormData] = useState(null);
  const [openMultiLoginAlert, setOpenMultiLoginAlert] = useState<boolean>(false);
  //for getting partner code
  const [partnerCode, setPartnerCode] = useState('');

  //checking if referer code is there or not
  const [hasreferer, setHasReferer] = useState(false);
  const [isNewUserNepali, setIsNewUserNepali] = useState<boolean>(false);

  useEffect(() => {
    clearState();
    if (isLoginPage || fromLogoutPage) setLogin(true);
    if (isSignUpPage) setLogin(false);
    dispatch(addData({ key: 'fromLogOut', value: false }));
  }, []);

  useEffect(() => {
    if (getSignupOtpStatus === 'success') {
      setExpandOtpForm(true);
      dispatch(
        addData({
          key: 'getSignupOtpStatus',
          value: '',
        })
      );
    }
  }, [getSignupOtpStatus === 'success']);

  useEffect(() => {
    if (verifySignupOtpStatus === 'success') {
      notification.success({ message: 'Verified successfully' });
      isGoogleLogin
        ? submitData('exchange/google-oauth2/', formData)
        : processSignup();
    } else if (verifySignupOtpStatus === 'failure') {
      notification.error({ message: 'Verification Failed' });
    }
    dispatch(
      addData({
        key: 'verifySignupOtpStatus',
        value: '',
      })
    );
  }, [verifySignupOtpStatus]);

  useEffect(() => {
    router?.query?.referer ? setLogin(false) : '';
  }, [router]);

  useEffect(() => {
    if (userData?.message?.is_device_verified === 'False') {
      window.location.pathname = '/verify-email';
    }
  }, [userData?.message]);

  //getting partner code from localStorage
  const partner = JSON.parse(window.localStorage.getItem('partner_code'));
  useEffect(() => {
    if (partner && partner !== 'undefined') {
      setPartnerCode(partner);
    }
  }, [partner]);

  useEffect(() => {
    if (userDataRegister?.error) {
      setOTP('');
      setExpandOtpForm(false);
    }
  }, [userDataRegister?.error]);

  useEffect(() => {
    if (userData?.status) {
      // notification.success({ message: 'User Logged in Successfully!' });
      dispatch(
        setData({
          name: 'creds',
          key: 'status',
          value: false,
        })
      );
    }
  }, [userData?.status]);

  useEffect(() => {
    typeof userData?.message === 'object' &&
      Object.keys(userData?.message).length > 0 &&
      !userData?.message?.hasOwnProperty('is_device_verified') &&
      setOpenMultiLoginAlert(true);
  }, [userData?.message]);

  const clearState = () => {
    setResendClick(false);
    setFormData({});
    setErrors({
      first_name: '',
      last_name: '',
      email: '',
      country_code: '',
      phone_number: '',
      password: '',
      referer: '',
    });
    setPassword2('');
    dispatch(
      addData({
        key: 'user',
        value: {
          country_code: '+977',
        },
      })
    );
    dispatch(
      addData({
        key: 'register',
        value: {},
      })
    );
    dispatch(
      setData({
        name: 'creds',
        key: 'message',
        value: '',
      })
    );
    dispatch(
      setData({
        name: 'creds',
        key: 'status',
        value: false,
      })
    );
  };

  const submitForm = (event) => {
    event.preventDefault();
    dispatch(userLogin(user));
  };

  const handleSelectFilter = (name, val) => {
    dispatch(setData({ name: 'user', key: name, value: val }));
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      user_type: is_instructor ? 'instructor' : 'client',
    });
    dispatch(setData({ name: 'user', key: name, value: value }));
    dispatch(
      setData({
        name: 'user',
        key: 'user_type',
        value: is_instructor ? 'instructor' : 'client',
      })
    );
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      authentication
    );
  }, []);

  //get Address(country, city, country-code) from ip
  useEffect(() => {
    if (isSignUpPage) {
      dispatch(getCountryCityCode());
    }
  }, [isSignUpPage]);

  const handleDefaultValue = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
      user_type: is_instructor ? 'instructor' : 'client',
    });
    dispatch(
      setData({
        name: 'user',
        key: name,
        value: value,
      })
    );
    dispatch(
      setData({
        name: 'user',
        key: 'user_type',
        value: is_instructor ? 'instructor' : 'client',
      })
    );
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  useEffect(() => {
    if (address?.data) {
      handleDefaultValue(
        'country',
        address?.data?.country ? address?.data?.country : undefined
      );
      handleDefaultValue(
        'city',
        address?.data?.city ? address?.data?.city : undefined
      );
      handleDefaultValue(
        'country_code',
        address?.data?.countryCode
          ? country_codes?.find((each) => each?.code == address?.data?.countryCode)
              ?.mobileCode
          : '+977'
      );
    }
  }, [address?.data]);

  const phoneValidation = (e) => {
    e.preventDefault();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      authentication,
      user?.country_code + user?.phone_number,
      appVerifier
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        setDisableResend(false);
        if (error?.code == 'auth/too-many-requests') {
          message.error('Too many requests. Please try again later.');
        } else if (error?.code == 'auth/quota-exceeded') {
          message.error('Quota Exceeded. Please try again later');
        } else {
          message.error(error?.message || 'Failed');
        }
        setExpandOtpForm(false);
        isGoogleLogin ? setphoneFormVisible(true) : '';
        window.recaptchaVerifier.recaptcha.reset();
      });
  };

  const submitSignupForm = (event) => {
    event.preventDefault();
    if (user?.phone_number?.length < 10) {
      setErrors({
        ...errors,
        phone_number: 'Phone number must be at least 10 digits',
      });
    }
    if (user?.password?.length <= 8) {
      setErrors({
        ...errors,
        password: 'Password must have at least 8 characters',
      });
    }
    if (!user?.country_code) {
      setErrors({
        ...errors,
        password: 'Please select country code',
      });
    }
    if (
      user?.phone_number?.length >= 10 &&
      user?.password?.length >= 8 &&
      user?.password === password2
    ) {
      if (user?.country_code === '+977') {
        setIsNewUserNepali(true);
        dispatch(requestSignupOtp({ phone_number: user?.phone_number }));
      } else {
        setExpandOtpForm(true);
        phoneValidation(event);
      }
    }
    // dispatch(userRegister(user));
  };

  const submitPhoneForm = (event) => {
    if (user?.phone_number?.length >= 10) {
      setphoneFormVisible(false);
      setExpandOtpForm(true);
      phoneValidation(event);
      // submitData('exchange/google-oauth2/', formData)
    }
  };

  const handleLogin = (to) => {
    if (isModal) {
      setLogin(!login);
    } else {
      router.push({ pathname: `/${to}`, query: '' });
    }
    clearState();
  };

  const googleResponse = async (response) => {
    setIsGoogleLogin(true);
    let values;
    setFormData({
      ...formData,
      access_token: response?.accessToken,
      country_code: user?.country_code,
      phone_number: user?.phone_number,
    });
    values = {
      access_token: response?.accessToken,
      country_code: user?.country_code,
      phone_number: user?.phone_number,
    };
    if (router?.query?.referer) {
      setFormData({
        ...formData,
        referer: router?.query?.referer,
      });
      values = {
        ...values,
        referer: router?.query?.referer,
      };
    }
    if (is_instructor) {
      setFormData({
        ...formData,
        user_type: 'instructor',
      });
      values = {
        ...values,
        user_type: 'instructor',
      };
    }
    const { data, success } = await new APIServices('phone-Verify-email/').post({
      email: response?.profileObj?.email,
    });
    if (success) {
      if (data?.is_phone_number_verified) {
        submitData('exchange/google-oauth2/', values);
      } else {
        // notification.error({ message: Object.values(data?.error || {})[0] });
        setphoneFormVisible(true);
      }
    }
  };

  const submitData = async (url, formValues) => {
    const { data, success } = await new APIServices(url).post(formValues);
    if (success) {
      window.localStorage.setItem('user', JSON.stringify(data?.user_data));
      dispatch(
        addData({
          key: 'creds',
          value: {
            isLoggedIn: true,
            access: data.access,
            user_data: data.user_data,
            status: true,
          },
        })
      );
      dispatch(
        addData({
          key: 'isLoggedIn',
          value: true,
        })
      );
      dispatch(
        setData({
          name: 'helperObj',
          key: 'loginModal',
          value: false,
        })
      );
      window.location.reload();
    } else {
      notification.warning({ message: 'Could not authenticate.' });
    }
  };

  const handleOtpChange = (e) => {
    // clearState();
    const { value } = e.target;
    if (value.length < 7) {
      const message = value.slice(0, 6);
      setOTP(message);
    }
  };

  const processSignup = () => {
    var userValues = user;
    if (router?.query?.referer) {
      userValues = {
        ...userValues,
        referer: router?.query?.referer,
      };
    }
    if (partnerCode) userValues = { ...userValues, partner_code: partnerCode };
    dispatch(userRegister(userValues));
  };

  const verifyNepalOtp = () => {
    dispatch(verifySignupOtp({ phone_number: user?.phone_number, code: OTP }));
  };

  const verifyOTP = (e) => {
    let confirmationResult = window.confirmationResult;
    setOTPLoader(true);
    confirmationResult
      .confirm(OTP)
      .then((result) => {
        // User signed in successfully.
        message.success('Verified successfully');
        setverifyError(null);
        isGoogleLogin
          ? submitData('exchange/google-oauth2/', formData)
          : processSignup();
        setOTPLoader(false);
      })
      .catch((error) => {
        message.error('Verification failed');
        error?.code == 'auth/invalid-verification-code'
          ? setverifyError('Invalid verification code')
          : setverifyError(error?.message || 'Verification Failed');
        setOTPLoader(false);
      });
  };

  const CustomGoogleButton = useCallback(({ onClick }) => {
    return (
      <div className="text-center">
        <button
          type="button"
          className="btn btn-block font-weight-normal google-button p-2 border border-muted d-flex justify-content-center bg-white  border-14"
          onClick={onClick}
        >
          <div className="mr-2 mt-1">
            <Image src="/images/svgs/google.svg" height="19" width="19" />
          </div>
          <span className="gray-da-text" style={{ marginTop: '2px' }}>
            Sign in with Google
          </span>
        </button>
      </div>
    );
  }, []);

  const signupForm = (
    <>
      <form onSubmit={submitSignupForm}>
        <div className="row ">
          <div className="col-lg-6">
            <div className="form-group">
              <label>First Name</label>
              <Input
                name="first_name"
                type="text"
                className="border-14"
                onChange={handleFilter}
                placeholder="First Name"
                value={user?.first_name || ''}
                size="large"
                required
              />
            </div>
            {userDataRegister?.error?.first_name && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.first_name}
              </span>
            )}
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Last Name</label>
              <Input
                name="last_name"
                type="text"
                className="border-14"
                onChange={handleFilter}
                placeholder="Last Name"
                value={user?.last_name || ''}
                size="large"
                required
              />
            </div>
            {userDataRegister?.error?.last_name && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.last_name}
              </span>
            )}
          </div>
        </div>
        <div className="form-group">
          <label>Email</label>
          <Input
            name="email"
            type="email"
            className="border-14"
            onChange={handleFilter}
            placeholder="Email"
            value={user?.email || ''}
            size="large"
            required
          />
        </div>
        {userDataRegister?.error?.email && (
          <span className="validation mb-2">
            <i className="fas fa-exclamation-triangle"></i>{' '}
            {userDataRegister?.error?.email}
          </span>
        )}
        {errors?.email && (
          <span className="validation">
            <i className="fas fa-exclamation-triangle"></i> {errors?.email}
          </span>
        )}
        <div className="row mt-1">
          <div className={`${isMobile ? 'col-4' : 'col-md-4 col-lg-4 col-sm-4'}`}>
            <div className="form-group country-code-selector">
              <label className="text-left ml-1">Code</label>
              <CountryCodeSelector
                onChange={(val) => handleSelectFilter('country_code', val)}
                defaultValue={
                  country_codes?.find(
                    (each) => each?.code == address?.data?.countryCode
                  )?.mobileCode || '+977'
                }
              />
            </div>

            {errors?.country_code && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {errors?.country_code}
              </span>
            )}
          </div>
          <div
            className={`${isMobile ? 'col-8' : 'col-md-8 col-lg-8 col-sm-8 pl-0'}`}
          >
            <div className="form-group">
              <label className="text-left ml-1">Phone</label>

              <Input
                id="phone_input"
                name="phone_number"
                type="number"
                onChange={handleFilter}
                placeholder="Phone Number"
                value={user?.phone_number || ''}
                size="large"
                required
                onKeyDown={blockInvalidChar}
                className="border-14"
              />
            </div>
            {userDataRegister?.error?.phone_number && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.phone_number}
              </span>
            )}
            {errors?.phone_number && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {errors?.phone_number}
              </span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Country</label>
              <Select
                showSearch
                style={{ width: '100%' }}
                value={user?.country || ''}
                // className="border-14"
                placeholder="Select Country"
                optionFilterProp="children"
                onChange={(val) => handleSelectFilter('country', val)}
              >
                {Object.entries(countries).map((each, idx) => (
                  <Option key={idx} value={each[1]}>
                    {each[1]}
                  </Option>
                ))}
              </Select>

              {/* <Input
                name="country"
                type="text"
                className="border-14"
                onChange={handleFilter}
                placeholder="Country"
                value={user?.country || ''}
                size="large"
                required
              /> */}
            </div>
            {userDataRegister?.error?.country && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.country}
              </span>
            )}
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>City</label>
              <Input
                name="city"
                type="text"
                className="border-14"
                onChange={handleFilter}
                placeholder="City"
                value={user?.city || ''}
                size="large"
                required
              />
            </div>
            {userDataRegister?.error?.city && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.city}
              </span>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label>Create Password</label>
              <Input.Password
                name="password"
                onChange={handleFilter}
                value={user?.password || ''}
                size="large"
                className="border-14"
              />
            </div>
            {userDataRegister?.error?.password && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.password}
              </span>
            )}
            {errors?.password && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i> {errors?.password}
              </span>
            )}
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Confirm Password</label>
              <Input.Password
                name="confirm_password"
                type="password"
                onChange={(e) => setPassword2(e.target.value)}
                value={password2 || ''}
                size="large"
                className="border-14"
              />
            </div>
            {user?.password && password2 && user?.password !== password2 ? (
              <span className="validation">
                <i className="fas fa-exclamation-triangle"></i> Passwords Do not
                Match
              </span>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="mb-3">
          <div className="d-flex gap-3 align-items-center">
            <p className="m-0 font-weight-bold">Have a Referral Code?</p>
            <Switch
              defaultChecked
              style={{ backgroundColor: '#1c599f' }}
              checked={hasreferer}
              onChange={(val) => setHasReferer(val)}
            />
          </div>
          {hasreferer && (
            <div className="row mt-2">
              <div className="col-lg-12">
                <div>
                  <div className="d-flex gap-1 align-items-center">
                    <label>Referer Code</label>
                    <Tooltip
                      placement="top"
                      title={'Please input username of users as referer code.'}
                    >
                      <InfoCircleFilled
                        rev={''}
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: '#f17425',
                          marginBottom: '8px',
                        }}
                      />
                    </Tooltip>
                  </div>

                  <Input
                    name="referer"
                    type="text"
                    onChange={handleFilter}
                    value={user?.referer || ''}
                    size="large"
                    className="border-14"
                    required
                  />
                </div>
                {errors?.referer && (
                  <span className="validation mb-2">
                    <i className="fas fa-exclamation-triangle"></i> {errors?.referer}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between">
          <label
            className="d-flex justify-content-start"
            htmlFor="termsAndCondition"
          >
            <CustomCheckBox>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="termsAndCondition"
                required
              />
            </CustomCheckBox>
            <span className="mt-auto">
              I Agree to{' '}
              <Link href="/terms-conditions">
                <a target="_blank" className="mt-auto text-secondary">
                  <u> Terms and Conditions.</u>
                </a>
              </Link>
            </span>
          </label>
        </div>
        <Button
          htmlType="submit"
          size="large"
          className="btn-danger text-white border-danger btn-block mt-2 mb-2 border-14"
        >
          Join Now
        </Button>
        <p className="account mb-3 text-center">
          Already have an account?{' '}
          <span onClick={() => handleLogin('login')} className="text-secondary">
            <u>Login</u>
          </span>
        </p>
      </form>

      <div className="row mt-2 mb-2">
        <span className="border-option col-lg-4 d-none d-lg-block ml-4 my-auto"></span>
        <p className="option col-lg-2 my-auto mx-auto text-center"> OR </p>
        <span className="border-option d-none d-lg-block col-lg-4 my-auto"></span>
      </div>
      <GoogleLogin
        clientId={process?.env?.NEXT_PUBLIC_GOOGLE_APP_ID}
        onFailure={(err) => console.log('err', err)}
        render={(renderProps) => (
          <CustomGoogleButton onClick={renderProps.onClick} />
        )}
        onSuccess={googleResponse}
        className="btn btn-block font-weight-normal"
        cookiePolicy={'single_host_origin'}
      />
    </>
  );

  const loginForm = (
    <>
      {typeof userData?.message === 'string' && userData?.message && (
        <div className="alert alert-danger">
          <p>{userData?.message}</p>
        </div>
      )}
      <form className="form" onSubmit={submitForm}>
        <div className="form-group">
          <label className="font-weight-normal">Email</label>
          <Input
            name="email"
            type="email"
            // className="form-control w-100"
            className="border-14"
            onChange={handleFilter}
            placeholder="Email"
            value={user?.email || ''}
            size="large"
          />
          {userData?.error?.email && (
            <span className="validation">
              <i className="fas fa-exclamation-triangle"></i>{' '}
              {userData?.error?.email}
            </span>
          )}
        </div>
        <div className="form-group">
          <label>Password</label>
          <Input.Password
            name="password"
            type="password"
            // className="form-control w-100"
            className="border-14"
            onChange={handleFilter}
            placeholder="Password"
            value={user?.password || ''}
            size="large"
          />
          {userData?.error?.password && (
            <span className="validation">
              <i className="fas fa-exclamation-triangle"></i>{' '}
              {userData?.error?.password}
            </span>
          )}
        </div>
        {/* <button className="btn btn-primary">Login</button> */}
        <Button
          htmlType="submit"
          size="large"
          className="btn-primary border-danger btn-block mt-2 mb-2 border-14"
        >
          Login
        </Button>
        <p className="account text-center mb-1">
          {' '}
          Don't have an account?{' '}
          <span className="text-secondary" onClick={() => handleLogin('signup')}>
            <u>Sign up</u>
          </span>
        </p>
        <div className="text-center">
          <Link href="/login/forgot-password">
            <p className="forgot-password text-secondary cursor-pointer d-inline-block">
              <u>Forgot Password?</u>
            </p>
          </Link>
        </div>
      </form>

      <div className="row mt-2 mb-2">
        <span className="border-option col-lg-4 d-none d-lg-block ml-4 my-auto"></span>
        <p className="option col-lg-2 my-auto mx-auto text-center"> OR </p>
        <span className="border-option d-none d-lg-block col-lg-4 my-auto"></span>
      </div>
      <GoogleLogin
        clientId={process?.env?.NEXT_PUBLIC_GOOGLE_APP_ID}
        // buttonText="SignIn With Google"
        onFailure={(err) => console.log('error', err)}
        render={(renderProps) => (
          <CustomGoogleButton onClick={renderProps.onClick} />
        )}
        onSuccess={googleResponse}
        className="btn btn-block font-weight-normal"
        // style={{ backgroundColor: 'red !important', justifyContent: 'center' }}
        cookiePolicy={'single_host_origin'}
      />
    </>
  );

  const otpClick = (e) => {
    if (!resendClick) setResendClick(true);
    setDisableResend(true);
    // submitSignupForm(e);
    var waitInterval = 60 * 3,
      display = document.querySelector('#countdownToOtp'),
      durationType = document.querySelector('#durationType');
    countdownHelper(waitInterval, display, durationType, timeUp);
    isNewUserNepali
      ? dispatch(requestSignupOtp({ phone_number: user?.phone_number }))
      : phoneValidation(e);
    setverifyError('');
  };

  const otpForm = (
    <div className="d-flex justify-content-center align-items-center container">
      <div className="py-3 px-3">
        <div className="title">
          <h5 className="banner-form-title">Mobile phone verification</h5>
        </div>
        <div className="text-center">
          Enter the code we just sent on your mobile phone{' '}
          <b className="text-danger">
            {user?.country_code}-{user?.phone_number}
          </b>
        </div>
        <input
          className="form-control mx-auto mt-5 p-2"
          id="phone_otp"
          onChange={(e) => handleOtpChange(e)}
          type="number"
          value={OTP}
        />
        <br />
        <Button
          className="ant-btn btn-primary border-14 border-danger mt-2 btn-block"
          onClick={isNewUserNepali ? verifyNepalOtp : verifyOTP}
          loading={isNewUserNepali ? verifySignupOtpStatus === 'request' : otpLoader}
          disabled={OTP?.length < 6}
        >
          Verify
        </Button>
        {verifyError ? (
          <Alert type="error" className="mt-4" message={verifyError} />
        ) : (
          ''
        )}
        {/* {!isNewUserNepali && ( */}
        <div className="text-center mt-3">
          <span className="d-block mobile-text">Didn't receive the code?</span>
          {/* <div>
            Resend OTP in <span id="countdownToOtp">03:00</span> minutes!
          </div> */}

          <Button
            className="button-outline-secondary cursor-pointer border-none mt-1"
            onClick={otpClick}
            disabled={disableResend}
          >
            Resend
          </Button>
        </div>
        {/* )} */}
      </div>
    </div>
  );

  const timeUp = () => {
    setDisableResend(false);
  };

  const phoneForm = (
    <>
      <h6 className="text-center mb-4 text-primary">
        Please provide your number for verification
      </h6>
      <form onSubmit={submitPhoneForm}>
        <div className="row mt-1">
          <div className={`${isMobile ? 'col' : 'col-md-5 col-lg-5 col-sm-5'}`}>
            <div className="form-group country-code-selector">
              <label className="text-left ml-1">Country Code</label>
              <CountryCodeSelector
                onChange={(val) => handleSelectFilter('country_code', val)}
                defaultValue={'+977'}
              />
              {/* <Select
                showSearch
                placeholder="Select country code"
                onChange={(val) => handleSelectFilter('country_code', val)}
                defaultValue="+977"
                size="large"
              >
                {country_codes &&
                  country_codes?.length !== 0 &&
                  country_codes?.map((item) => (
                    <Select.Option value={item.mobileCode} key={item.mobileCode}>
                      {`${item.mobileCode}(${item.code})`}
                    </Select.Option>
                  ))}
              </Select> */}
            </div>

            {errors?.country_code && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {errors?.country_code}
              </span>
            )}
          </div>
          <div className={`${isMobile ? 'col' : 'col-md-7 col-lg-7 col-sm-7'}`}>
            <div className="form-group">
              <label className="text-left ml-1">Phone</label>

              <Input
                name="phone_number"
                type="number"
                onChange={handleFilter}
                placeholder="Phone Number"
                value={user?.phone_number || ''}
                size="large"
                required
                onKeyDown={blockInvalidChar}
                className="border-14"
                autoComplete="off"
              />
            </div>
            {errors?.phone_number && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {errors?.phone_number}
              </span>
            )}
          </div>
        </div>
        <Button
          htmlType="submit"
          size="large"
          className="btn-primary border-danger btn-block mt-2 mb-2 border-14"
        >
          Submit
        </Button>
      </form>
    </>
  );

  const logoutAllDevice = () => {
    axios({
      method: 'POST',
      headers: {
        Authorization: `Token ${userData?.message?.token}`,
      },
      url: `${process.env.NEXT_PUBLIC_BASE_URL}user-logout-all-devices/`,
    })
      .then((res) => {
        setOpenMultiLoginAlert(false);
        notification.success({ message: 'Logged out of all devices successfully' });
      })
      .catch((e) => {
        notification.error({ message: 'Failed to log out' });
      });
  };

  return (
    <Spin spinning={signUpStatus === 'request' ? true : false}>
      <div id="login-component">
        {!expandOtpForm && (
          <div className="title" style={{ marginBottom: '2px !important' }}>
            <h5 className="banner-form-title">Get Started with Learnsic!</h5>
          </div>
        )}
        <Modal
          title="Multiple Login detected"
          visible={openMultiLoginAlert}
          onCancel={() => setOpenMultiLoginAlert(false)}
          footer={false}
          centered
        >
          {userData?.message?.body?.length !== 0 &&
            userData?.message?.body?.map((each) => (
              <div className="d-flex">
                <div className="font-size-large gray-da-text">
                  <i className="fas fa-desktop"></i>
                </div>
                <div className="d-flex flex-column p-3 px-4">
                  <div className="font-weight-bold">
                    {each?.platform} -{each?.browser_name}
                  </div>
                  <div className="gray-da-text">{each?.ip_address}</div>
                </div>
              </div>
            ))}
          <div className="text-center mt-3">
            <Button
              className="btn-secondary border-danger"
              onClick={logoutAllDevice}
            >
              Logout of all devices
            </Button>
          </div>
        </Modal>
        {phoneFormVisible
          ? phoneForm
          : expandOtpForm
          ? otpForm
          : login
          ? loginForm
          : signupForm}
        <div
          className={`text-center ${expandOtpForm && disableResend ? '' : 'd-none'}`}
        >
          {disableResend && resendClick && (
            <Alert
              message="Verification code has been sent successfully"
              type="success"
              className="mb-1"
            />
          )}
          Resend OTP in <span id="countdownToOtp">03:00</span>{' '}
          <span id="durationType">minutes</span>!
        </div>
        <div id="recaptcha-container"></div>
      </div>
    </Spin>
  );
};

export default memo(Login);
